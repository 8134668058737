<template>
  <div class="row no-gutters text-center" style="height: 80vh; overflow-y: auto">
    <div class="col-12">
      <div style="background: #e1e1e1; position: sticky; top: 0;">
        <table class="small e-tbl">
          <thead class="bg-white" style="position: sticky; top: 0; z-index: 2">
          <tr>
            <th style="width: 15%">Vessel Name</th>
            <th style="width: 15%">Report Date</th>
            <template v-if="company_instructions[0]?.children !== undefined && company_instructions[0]?.children.length > 0">
              <th style="width: 15%">Deficiency Code</th>
              <th style="width: 15%">Deficiency Description</th>
              <th style="width: 15%">Action Code</th>
            </template>
            <template v-else-if="company_instructions[0]?.ci_type === 'ia'">
              <th style="width: 15%">Management Office</th>
              <th style="width: 15%">Title</th>
              <th style="width: 15%">Category</th>
            </template>
            <template v-else>
              <th style="width: 15%">Management Offices</th>
              <th style="width: 15%">Title</th>
              <th style="width: 15%">Code</th>
            </template>
            <th style="width: 7%"></th>
          </tr>
          </thead>
        </table>
      </div>
      <template v-if="company_instructions">
          <template v-if="Array.isArray(company_instructions) ">
            <template v-for="(instruction, index) in company_instructions">
              <table class="small table table-sm table-borderless mb-0" style="margin-top: 1rem;">
                <tbody>
                <tr style="cursor: pointer" @click="toggleAccordion(index)">
                  <td style="width: 15%;font-size: 13px;" class="align-middle">

                      <span style="position: absolute; left: 10px; z-index: -100">
                        <font-awesome-icon v-if="CIactivetrees.includes(index)" icon="chevron-down"/>
                        <font-awesome-icon v-else icon="chevron-right"/>
                      </span>
                      <strong>
                        {{ instruction.vessel | toUpper }}
                      </strong>


                  </td>
                  <td style="width: 15%; font-size: 13px" class="align-middle"><strong>{{ instruction.date }}</strong></td>
                  <template v-if="instruction?.children !== undefined && instruction?.children?.length > 0">
                    <td style="width: 15%; font-size: 13px" class="align-middle">({{ instruction.children[0].code }}) {{ instruction.children[0].code_description }}</td>
                    <td style="width: 15%; font-size: 13px" class="align-middle">{{ instruction.children[0].desription }}</td>
                    <td style="width: 15%; font-size: 13px" class="align-middle">({{ instruction.children[0].action_code }}) {{ instruction.children[0].action_code_description }}</td>
                  </template>

                    <template v-else-if="company_instructions[0]?.ci_type === 'ia'">
                    <td style="width: 15%; font-size: 13px" class="align-middle">{{ instruction.mangement_office | toUpper }}</td>
                    <td style="width: 15%; font-size: 13px" class="align-middle">{{ instruction.title | toUpper }}</td>
                    <td style="width: 15%; font-size: 13px" class="align-middle">{{ instruction.category }}</td>
                  </template>

                  <template v-else>
                    <td style="width: 15%; font-size: 13px" class="align-middle">{{ instruction.mangement_office | toUpper }}</td>
                    <td style="width: 15%; font-size: 13px" class="align-middle">{{ instruction.title | toUpper }}</td>
                    <td style="width: 15%; font-size: 13px" class="align-middle">{{ instruction.code }}</td>
                  </template>

                  <td style="width: 7%" class="align-middle">
                    <button class="e-btn e-btn-blue e-btn-sm e-text-white my-1" @click.stop="redirectTree(instruction)">
                      <font-awesome-icon icon="eye"/>
                      View
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
              <hr >
              <company-instruction-sub-tree v-if="CIactivetrees.includes(index)" :key="instruction.id" :is-shown="showInfo" @viewInstruction="viewInstruction" :node="instruction"></company-instruction-sub-tree>
            </template>
          </template>
          <template v-else>
            <table class="small table table-sm table-borderless w-75 mb-0">
              <tbody>
              <tr>
                <td style="width: 20em" class="align-middle">{{ company_instructions.vessel | toUpper }}</td>
                <td style="width: 20em" class="align-middle">{{ company_instructions.mangement_office | toUpper }}</td>
                <td style="width: 20em" class="align-middle">{{ company_instructions.title | toUpper }}</td>
                <td style="width: 10em" class="align-middle">{{ company_instructions.date }}</td>
                <td style="width: 10em" class="align-middle">
                  <button class="e-btn e-btn-blue e-btn-sm e-text-white my-1" @click.stop="redirectTree(company_instructions)">
                    <font-awesome-icon icon="eye"/>
                    View
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
            <company-instruction-sub-tree :is-shown="showInfo" @viewInstruction="viewInstruction" :node="company_instructions"></company-instruction-sub-tree>
          </template>
      </template>
    </div>

    <CIModal id="view-instruction" :key="'view-instruction'" style="overflow: auto">
      <template v-slot:header>
        <div class="row px-2 align-items-center">
          <div class="col-auto">
            <template v-if="instruction.taggable_is_confidential">
              <router-link v-if="instruction.taggable_deficiency_id"
                           id="exampleModalLongTitle" class="font-weight-normal modal-title pb-0 mr-0 col-10 text-left"
                           :to="{name:'PscMain',params:{id:instruction.taggable_id}}"
                           target="_blank" style="font-size: 15px;">
                <span class="p e-text-black" style="font-size: 15px;">REPORT NO.</span>
                {{ instruction.taggable_code  | toUpper }} - {{ instruction.taggable_title | toUpper }}
              </router-link>

              <router-link v-else
                           id="exampleModalLongTitle" class="font-weight-normal modal-title pb-0 mr-0 col-10 text-left"
                           :to="{name:'AccidentIncidentReport',params:{id:instruction.taggable_id}}"
                           target="_blank" style="font-size: 15px;">
                <span class="p e-text-black" style="font-size: 15px;">REPORT NO.</span>
                {{ instruction.taggable_code  | toUpper }} - {{ instruction.taggable_title | toUpper }}
              </router-link>
            </template>
            <template v-else>
              <div class="col-10">
                <p class="font-weight-normal modal-title pb-0 mr-0 text-left text-uppercase" style="font-size: 15px;">
                  REPORT NO. {{ instruction.report_code }} - {{ instruction.report_title }} </p>
              </div>
            </template>
          </div>
          <template v-if="user.vessel_id">
            <div class="col-auto">
              <p class="my-0 font-weight-bold">Acknowledgement Status:</p>
            </div>
            <div class="col-auto">
              <template v-if="!!instruction.vessel">
                <button class="ml-0 d-flex align-items-end e-btn e-btn-sm" :class="{
                  'bg-danger':instruction.vessel.cancelled_at,
                  'bg-4c4c4c':!instruction.vessel.cancelled_at && instruction.vessel.acknowledgement_status_id ==1,
                  'bg-042454':!instruction.vessel.cancelled_at && instruction.vessel.acknowledgement_status_id == 2,
                  'bg-42b983':!instruction.vessel.cancelled_at && instruction.vessel.acknowledgement_status_id == 3,
                  'bg-1b4f72':!instruction.vessel.cancelled_at && instruction.vessel.acknowledgement_status_id == 4,
                  'bg-1b4f72':!instruction.vessel.cancelled_at && instruction.vessel.acknowledgement_status_id == 5,
                }">
                  <small class="text-white">{{ instruction.vessel.cancelled_at ? 'CANCELLED' : instruction.vessel.acknowledgement_status }}</small>
                </button>
              </template>
            </div>
            <div class="col-auto">
              <p class="my-0 font-weight-bold">Job Done Status:</p>
            </div>
            <div class="col-auto">
              <template v-if="!!instruction.vessel">
                <button class="ml-0 d-flex align-items-end e-btn e-btn-sm" :class="{
                  'bg-danger':instruction.vessel.cancelled_at,
                  'bg-574e4e':!instruction.vessel.cancelled_at && instruction.vessel.job_status_id ==1,
                  'bg-056cb4':!instruction.vessel.cancelled_at && instruction.vessel.job_status_id ==2,
                  'bg-42b983':!instruction.vessel.cancelled_at && instruction.vessel.job_status_id ==3,
                }">
                  <small class="text-white">{{ instruction.vessel.cancelled_at ? 'CANCELLED' : instruction.vessel.job_status }}</small>
                </button>
              </template>
            </div>
          </template>
        </div>
        <button type="button" class="close ml-0" @click="resetInstruction">
          <span aria-hidden="true">&times;</span>
        </button>
      </template>
      <template v-slot:body>
        <form>
          <div class="row align-items-center mb-2">
            <div class="col-lg-2">
              <h6 class="font-weight-bold">Instruction</h6>
            </div>
            <div class="col-lg-10">
                <input type="text" class="form-control form-control-sm" :value="instruction.title | toUpper"
                       disabled
                       name="instruction_title"
                       id="instruction_title">
            </div>
          </div>
          <div class="row align-items-center mb-2">
            <div class="col-lg-2">
              <h6 class="font-weight-bold">Type of Instruction</h6>
            </div>
            <div class="col-4">
              <input type="text" class="form-control form-control-sm"
                     :value="instruction.instruction_type | toUpper"
                     disabled
                     name="instruction_type"
                     id="instruction_type">
            </div>
          </div>
          <div class="row align-items-center mb-2">
            <div class="col-lg-2">
              <h6 class="font-weight-bold">Related Parties</h6>
            </div>
            <div class="col-lg-4">
              <input type="text" class="form-control form-control-sm"
                     :value="instruction.related_party | toUpper"
                     disabled
                     name="instruction_type"
                     id="instruction_type">
            </div>
          </div>
          <div class="row align-items-center mb-2">
            <div class="col-lg-2">
              <h6 class="font-weight-bold">Instruction Detail</h6>
            </div>
            <div class="col-lg-10 text-left">
              <div id="textArea" class="pre-wrap-txt textarea-display-card"  v-html="instruction.detail || '' " />
            </div>
          </div>

          <template v-if="!user.vessel_id">
            <div class="row align-items-center mb-3">
              <div class="col-2">
                <h6 class="font-weight-bold"> Attachments</h6>
              </div>
              <div class="col-lg-10 text-left">
                <div class="row">
                  <div class="col-2"  v-for="attachment in instructionFiles">
                    <other-comp :no-delete="true"
                                @viewOther="viewInstructionAttachment(attachment,false)"
                                :attachment="attachment" attachment-name="name" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row align-items-center mb-2">
              <div class="col-lg-2">
                <h6 class="font-weight-bold">Vessels</h6>
              </div>
              <div class="col-10">
                <div class="row" style="max-height: 40vh;overflow: auto">
                  <div class="col-6 mb-1" v-for="vessel in instruction.vessels ">
                    <div class="row">
                      <div class="col-4 align-self-center">
                        <div class="row align-items-center">
                          <font-awesome-icon icon="ship" /> {{vessel.vessel | toUpper}}
                        </div>
                      </div>
                      <div class="col-3 align-self-center">
                          <span class="font-weight-bolder" :class="{
                          'text-danger':vessel.cancelled_at,
                          'text-574e4e':!vessel.cancelled_at && vessel.job_status_id ==1,
                          'text-056cb4':!vessel.cancelled_at && vessel.job_status_id ==2,
                          'text-42b983':!vessel.cancelled_at && vessel.job_status_id ==3,
                          }">{{ vessel.cancelled_at ? 'CANCELLED' : vessel.job_status | toUpper }}
                          </span>
                      </div>
                      <div class="col-2 align-self-center">
                          <span class="font-weight-bolder" :class="{
                              'text-danger':vessel.cancelled_at,
                              'text-4c4c4c':!vessel.cancelled_at && vessel.acknowledgement_status_id ==1,
                              'text-042454':!vessel.cancelled_at && vessel.acknowledgement_status_id == 2,
                              'text-42b983':!vessel.cancelled_at && vessel.acknowledgement_status_id == 3,
                              'text-1b4f72':!vessel.cancelled_at && vessel.acknowledgement_status_id == 4,
                            }">
                          {{vessel.cancelled_at ? 'CANCELLED' : vessel.acknowledgement_status | toUpper }}
                        </span>
                      </div>
                      <div class="col-1">
                        <button type="button" title="View Instruction" class="e-btn e-btn-blue btn-xs" @click="viewVesselResponse(vessel,false)">
                          <font-awesome-icon icon="eye" class="force-white-all" /> <small class="text-white">View</small>
                        </button>
                      </div>
                      <div class="col-1 align-self-center">
                        <button type="button" title="View Instruction in New Tab" class="e-btn e-btn-blue btn-xs" @click="viewVesselResponse(vessel,true)">
                          <font-awesome-icon icon="share" class="force-white-all" /> <small class="text-white">View</small>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="row align-items-center mb-3">
              <div class="col-2">
                <h6 class="font-weight-bold">Instruction Attachments</h6>
              </div>
              <div class="col-lg-10 text-left">
                <div class="row">
                  <div class="col-2"  v-for="attachment in instructionFiles">
                    <other-comp :no-delete="true"
                                @viewOther="viewInstructionAttachment(attachment,false)"
                                :attachment="attachment" attachment-name="name" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-2">
                <h6 class="font-weight-bold">Job Done</h6>
              </div>
              <div class="col-lg-10 text-left">
                <template v-if="!!instruction.vessel">

                  <vue-editor v-if="!instruction.vessel.cancelled_at" :editor-toolbar="toolbar" v-model="instruction.vessel.response"></vue-editor>
                  <div id="textArea"  v-else  class="pre-wrap-txt textarea-display-card"  v-html="instruction.vessel.response || '' " />
                </template>
              </div>
            </div>

            <div class="row align-items-center mb-3">
              <div class="col-2">
                <h6 class="font-weight-bold">Attachments</h6>
              </div>
              <div class="col-lg-10 text-left">
                <template v-if="!!instruction.vessel">
                  <div class="row justify-content-start mr-1 mb-2 mt-1"  v-if="!instruction.vessel.cancelled_at">
                    <template v-if="!isAddAttachment">
                      <button  class="e-btn e-btn-green ml-3" id="addBtn" @click="isAddAttachment = !isAddAttachment" type="button">Add Attachment</button>
                    </template>
                    <template v-else>
                      <button href="#" class="e-btn e-btn-blue ml-3" id="addBtn" type="button" @click="cancelAddAttachment">Cancel</button>
                    </template>
                  </div>
                </template>

                <input type="file" v-if="isAddAttachment"
                       ref="ci_files"
                       class="form-control-file mb-2" id="docLists"
                       @change.prevent="saveAttachments"
                       multiple>

                <div class="row mb-2" v-else>
                  <div class="col-2"  v-for="attachment in instructionAttachments">
                    <other-comp @viewOther="viewInstructionAttachment(attachment)"
                                @deleteOther="confirmDeleteAttachment(attachment)"
                                :attachment="attachment" attachment-name="name" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row align-items-center mb-2">
              <div class="col-lg-2">
                <h6 class="font-weight-bold">Job Status</h6>
              </div>
              <div class="col-lg-4">
                <template v-if="!!instruction.vessel">
                  <select class="form-control form-control-sm" :disabled="instruction.vessel.cancelled_at" v-model="instruction.vessel.job_status_id">
                    <option :value="status.id" v-for="status in jobStatuses">
                      {{status.name | toUpper}}
                    </option>
                  </select>
                </template>
              </div>
            </div>
            <template v-if="!!instruction.vessel">
              <div class="row align-items-center mb-2">
                <div class="col-lg-2">
                  <h6 class="font-weight-bold">Acknowledgement Status</h6>
                </div>
                <div class="col-lg-4">
                  <select class="form-control form-control-sm" disabled v-model="instruction.vessel.acknowledgement_status_id">
                    <option :value="status.id" v-for="status in acknowledgementStatuses">
                      {{status.name | toUpper}}
                    </option>
                  </select>
                </div>
              </div>
            </template>
            <div class="row align-items-center mb-2">
              <div class="col-lg-2">
                <h6 class="font-weight-bold">Comments</h6>
              </div>
              <div class="col-lg-10">
               <CIComment v-if="!!instruction.vessel" :ciVesselId="instruction.vessel.id" :vessel-response="instruction.vessel"></CIComment>
              </div>
            </div>
            <div class="modal-footer">
                <button class="e-btn e-btn-green e-btn-lg" type="button" v-show="!!instruction.vessel && !instruction.vessel.cancelled_at && isVesselResponseModified" @click="sendResponse">Submit</button>
            </div>
          </template>
        </form>
      </template>
    </CIModal>
    <CIModal id="view-vessel-response" :key="'view-vessel-response'">
      <template v-slot:header>
        <template v-if="!!vesselResponse">
          <h5 class="font-weight-bold">Vessel Response : {{vesselResponse.vessel | toUpper}}</h5>
        </template>
        <button type="button" class="close" @click="resetVesselResponse('view-vessel-response',true)">
          <span aria-hidden="true">&times;</span>
        </button>
      </template>
      <template v-slot:body>
        <div class="row align-items-center mb-2">
          <div class="col-lg-2">
            <h6 class="font-weight-bold">Job Completion:</h6>
          </div>
          <div class="col-lg-4">
            <template v-if="vesselResponse">
              <select class="form-control form-control-sm" v-model="vesselResponse.job_status_id"
                      name="job-status" id="job-status" disabled>
                <option :value="jobStatus.id" v-for="jobStatus in jobStatuses">{{jobStatus.name}}</option>
              </select>
            </template>
          </div>
        </div>
        <div class="row align-items-center mb-2">
          <div class="col-lg-2">
            <h6 class="font-weight-bold">Job Done:</h6>
          </div>
          <div class="col-lg-10">
            <template v-if="!!vesselResponse">
              <div id="textArea" class="pre-wrap-txt textarea-display-card" v-html="vesselResponse.response || '' "/>
            </template>
          </div>
        </div>
        <div class="row align-items-center mb-2">
          <div class="col-lg-2">
            <h6 class="font-weight-bold">Attachments:</h6>
          </div>
          <div class="col-lg-10">
            <div class="row mb-2">
              <div class="col-2"  v-for="attachment in instructionAttachments">
                <other-comp :no-delete="true"
                            @viewOther="viewInstructionAttachment(attachment,true)"
                            :attachment="attachment" attachment-name="name" />
              </div>
            </div>
          </div>
        </div>

        <div class="row align-items-center mb-2">
          <div class="col-lg-2">
            <h6 class="font-weight-bold">Acknowledgement:</h6>
          </div>
          <div class="col-lg-5" v-if="true">
            <div class="row">
              <div class="col-auto">
                <template v-if="$route.query.type !== 'ia_oe'">
                  <div class="row">
                    <template v-if="!!vesselResponse">
                      <div class="col-auto">
                        <template>
                          <select disabled class="form-control form-control-sm"  :disabled="vesselResponse.cancelled_at" v-model="vesselResponse.acknowledgement_status_id"
                                  name="job-status" id="job-status">
                            <option :value="ackStatus.id" v-for="ackStatus in acknowledgementStatuses">{{ackStatus.name}}</option>
                          </select>
                        </template>
                      </div>
                      <div class="col-auto" v-show="isVesselResponseModified">
                        <button class="btn btn-sm btn-success" :disabled="vesselResponse.cancelled_at" @click.prevent="sendAcknowledgementStatus">
                          SAVE
                        </button>
                      </div>
                    </template>
                  </div>
                </template>
                <template v-if="$route.query.type === 'ia_oe'">
                  <template v-if="user.is_vessel === false">
                    <div class="row">
                      <template v-if="!!vesselResponse">
                        <div class="col-auto">
                          <template v-if="isAllowed('vessel-ia.appointment.non-conformity.objective-evidence.ci.edit-acknowledgment-status') === false">
                            {{ acknowledgementStatuses.length > 0 ? acknowledgementStatuses.filter(status => status.id === vesselResponse?.acknowledgement_status_id)[0]?.name : '' }}
                          </template>
                          <template v-else>
                            <select disabled class="form-control form-control-sm"  :disabled="vesselResponse.cancelled_at" v-model="vesselResponse.acknowledgement_status_id"
                                    name="job-status" id="job-status">
                              <option :value="ackStatus.id" v-for="ackStatus in acknowledgementStatuses">{{ackStatus.name}}</option>
                            </select>
                          </template>

                        </div>
                        <template v-if="isAllowed('vessel-ia.appointment.non-conformity.objective-evidence.ci.edit-acknowledgment-status') === true">
                          <div class="col-auto" v-show="isVesselResponseModified">
                            <button class="btn btn-sm btn-success" :disabled="vesselResponse.cancelled_at" @click.prevent="sendAcknowledgementStatus">
                              SAVE
                            </button>
                          </div>
                        </template>
                      </template>
                    </div>
                  </template>
                </template>
              </div>
            </div>
          </div>

        </div>

        <div class="row align-items-center mb-2">
          <div class="col-lg-2">
            <h6 class="font-weight-bold">Acknowlegement Status Updated By:</h6>
          </div>
          <div class="col-lg-5 text-left">
            <span class="p">{{vesselResponse.ack_status_updated_by | toUpper}}</span>
          </div>
        </div>

        <div class="row align-items-center mb-2">
          <div class="col-lg-2">
            <h6 class="font-weight-bold">Comments:</h6>
          </div>
          <div class="col-lg-10">
            <template v-if="!!vesselResponse">
              <CIComment :key="vesselResponse.id" :vessel-response="vesselResponse" v-if="!!vesselResponse.id" :ciVesselId="vesselResponse.id"></CIComment>
            </template>
          </div>
        </div>
      </template>

    </CIModal>
  </div>
</template>

<script>
import CompanyInstructionSubTree from "./CompanyInstructionSubTree.vue";
import CIModal from "../../ci/CIModal.vue";
import {mapActions, mapGetters} from "vuex";
import {AlertService} from "../../../../services/AlertService";
import OtherComp from "../../../elements/OtherComp.vue";
import axios from "axios";
import {CI_INDEX, CI_VESSEL_INDEX} from "../../../../services/ConstantService";
import {FileService} from "../../../../services/FileService";
import CIComment from "../../ci/CIComment.vue";
import ObjectMixin from "@/mixins/ObjectMixin";
import {permissionMixin} from "@/mixins/permissionMixins";

export default {
  name: "CompanyInstructionTree",
  components: {CIComment, OtherComp, CIModal, CompanyInstructionSubTree},
  props: {
    company_instructions: {
      type: Object | Array,
      default: null,
    }
  },
  mixins:[ObjectMixin, permissionMixin],
  data() {
    return {
      activetrees: [],
      showInfo:true,
      isAddAttachment:false,
      instruction: {},
      vessels:[],
      isVesselResponseModified:false,
      toolbar: [
        ['bold', 'italic', 'strike', 'underline'],
        [{
          'color': ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff",
            "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff",
            "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff",
            "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2",
            "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466"]
        }]
      ],
      vesselResponse:{},
      tempVesselResponse:{}
    }
  },
  computed:{
    ...mapGetters(['user','jobStatuses','acknowledgementStatuses','instructionAttachments','instructionFiles', "CIactivetrees", 'openedCompanyInstruction']),
  },
  created() {
    this.company_instruction_list = this.company_instructions
  },
  methods:{
    ...mapActions([
      'sendVesselResponse',
      'addVesselAttachments',
      'getVesselAttachments',
      'deleteVesselAttachment',
      'getCompanyInstructionAttachments'
    ]),

    redirectTree(instruction){
      if(this.$route.query.type === 'ia_oe'){
        const route= this.$router.resolve(
          {
              name: 'IaCompanyInstructions',
              params: {
                vessel_id : instruction.vessel_id,
                id: instruction.audit_record_id,
                type : instruction.type,
                ncr_id : instruction.ncr_id,
                ncr_type : instruction.ncr_type,
                oe_id : instruction.id,
              },
            },
          )
          window.open(route.href,'_blank');

      } else {
        const route = this.$router.resolve({
            name: this.$route.query.type === 'air' ? 'AccidentIncidentReport' : 'PscMain',
            params: {id: instruction.id},
          });
          window.open(route.href, '_blank');
        }
    },

    resetInstruction(){
      this.instruction= {}
      this.vessels=[]
      $('#view-instruction').modal('hide')
      this.$store.commit('SET_COMPANY_INSTRUCTION_COMMENTS',[])
      this.$emit('resetCompanyInstruction')
    },
    viewInstruction(event){
      this.instruction=event

      let openedInstruction = this.instruction;
      openedInstruction.vessel_id = this.instruction.vessel_audit_id;
      this.$store.commit('SET_OPENED_COMPANY_INSTRUCTION', this.instruction);
      this.vessels=event.vessels.map(vessel=>vessel.vessel_id)
      if(this.user.vessel_id){
        this.tempVesselResponse=Object.assign({},this?.instruction?.vessel)
        this.getVesselAttachments({id:this.instruction.vessel.id});
      }
      this.getCompanyInstructionAttachments({id:this.instruction.id})
      this.$nextTick().then(()=>{
        $('#view-instruction').modal('show')
      })
    },

    resetVesselResponse() {
      this.vesselResponse={}
      $('#view-vessel-response').modal('hide')

      $('#view-instruction').css({
        zIndex : 1050
      });
    },

    viewVesselResponse(vessel,newTab = false){
      if(newTab){
        var w = window.window.innerWidth + 100;
        var h= window.window.innerHeight + 100;
        const route = this.$router.resolve(
          {
            name: 'StaffVesselResponse',
            query:{
              id:vessel.id
            }
          },
        )
        window.open(route.href, '_blank',`toolbar=yes,scrollbars=yes,resizable=yes,width=${w},height=${h}`)
      }
      else {
        this.tempVesselResponse=Object.assign({},vessel)
        this.vesselResponse=Object.assign({},vessel)
        this.getVesselAttachments({id:vessel.id});
        this.$nextTick().then(()=>{
          $('#view-vessel-response').modal('show')
        })

        $('#view-instruction').css({
          zIndex : 1040
        });
      }
    },
    async sendResponse(){
      if(await AlertService.confirmUpdateAlert()){
        const vesselParams={
          id:this.instruction.vessel.id,
          job_status_id:this.instruction.vessel.job_status_id,
          response:this.instruction.vessel.response
        }
        const response =await this.sendVesselResponse(vesselParams)
        if(response) {
          AlertService.successAlert('Vessel Response Updated Successfully','Send Response')
          this.instruction={}
          $('#view-instruction').modal('hide')
          this.$emit('sendResponse')
        }
      }
    },

    async sendAcknowledgementStatus(){
      if(await AlertService.confirmUpdateAlert()){
        const vesselParams={
          id:this.vesselResponse.id,
          acknowledgement_status_id:this.vesselResponse.acknowledgement_status_id,
        }
        const response =await this.sendVesselResponse(vesselParams)
        if(response) {
          AlertService.successAlert('Acknowledgement Status Updated Successfully','Send Acknowledgement Status')
          this.instruction=Object.assign({},response)
          const vessel=this.instruction.vessels.find(item=>item.id==this.vesselResponse.id);
          this.vesselResponse=Object.assign({},vessel)
          this.tempVesselResponse=Object.assign({},vessel)
          this.isVesselResponseModified=false;
          this.$emit('sendResponse')
        }
      }
    },
    async saveAttachments(){
      const formData = new FormData();
      if(this.$refs.ci_files.files) {
        Array.prototype.forEach.call(this.$refs.ci_files.files, file => {
          formData.append("files[]", file)
        });
      }
      const params={id:this.instruction.vessel.id,form:formData}
      const response = await this.addVesselAttachments(params);
      if(response) {
        AlertService.successAlert('Attachment Added Successfully','Add Attachments')
        this.isAddAttachment=false;
      }
    },
    async cancelAddAttachment(){
      if(await AlertService.cancelAlert()) {
        this.isAddAttachment=false;
      }
    },
    async viewInstructionAttachment(attachment,fromVessel=true) {
      swal.fire({
        title: 'Please wait',
      });
      swal.showLoading();

      const mainRoute= fromVessel ? CI_VESSEL_INDEX : CI_INDEX

      const url= [mainRoute,attachment.referencable_id,'attachment',attachment.id].join('/')

      const response = await axios.get(url ,{responseType: 'blob'})

      if (response && response.status == 200) {
        if (attachment.extension === 'pdf' || attachment.mime_type.includes("image")) {
          await FileService.viewDocument(response.data,attachment)
        }
        else {
          await FileService.downloadDocument(response.data,attachment,'name');
        }
        swal.close();
      }
      else {
        AlertService.errorAlert('File not exists',"View Vessel Attachment")
      }
    },
    async confirmDeleteAttachment(attachment) {
      if(await AlertService.confirmDeleteAlert('DELETE VESSEL ATTACHMENT','Do you want to delete attachment ?','Yes Proceed')){
        const response = this.deleteVesselAttachment({id:attachment.referencable_id,attachment_id:attachment.id});
        if(response) {
          AlertService.successAlert('Attachment deleted Successfully','Delete Attachments')
        }
      }
    },
    toggleAccordion(index) {
      if (this.activetrees.includes(index)) {
        this.activetrees = this.activetrees.filter(i => i !== index);
      } else {
        this.activetrees.push(index);
      }
      this.$store.commit('SET_ACTIVE_TREES',this.activetrees)
    }
  },
  watch:{
    'instruction.vessel.job_status_id'(){
      this.isVesselResponseModified=this.dataDiff(this?.tempVesselResponse ?? null,this?.instruction?.vessel ?? null)
    },
    'instruction.vessel.response'(){
      this.isVesselResponseModified=this.dataDiff(this?.tempVesselResponse ?? null,this?.instruction?.vessel ?? null)
    },
    'vesselResponse.acknowledgement_status_id'(){
      this.isVesselResponseModified=this.dataDiff(this?.tempVesselResponse ?? null,this.vesselResponse ?? null)
    }

  }
}

</script>
<style scoped>
.checker {
  height: 0px;
}


td:nth-child(2),
td:nth-child(3) {
  width: 10%;
}

td:nth-child(5),
td:nth-child(6),
td:nth-child(7) {
  width: 7%;
}
#exampleModalLongTitle:hover {
  text-decoration: none;
}
</style>
